import React from "react";
import cwg from "../img/cwg.png";

export let colors = ["rgb(1, 208, 108)", "rgb(5, 186, 199)"];

/**
 * Generates and returns a font awesome
 * icon for the name provided
 * @param {string} name
 * @param {string} [mainClass] the main icon class (fab, fas, fa...)
 */
export const fetchIcon = (name, mainClass = 'fab') => {
  return <i className={`${mainClass} fa-${name}`} aria-hidden="true" />
};

export const info = {
  firstName: "Classy",
  lastName: "Guy",
  initials: "CWG",
  positions: ["Watch Collector", "Real estate Investor", "Developer", "Car Enthusiast"],
  selfPortrait: cwg,
  gradient: `linear-gradient(135deg, ${colors})`,
  miniBio: [
    ["💼", "Real Estate Developer at CWG Capital"],
    ["⌚", "Watch Afficianado"],
    ["🇺🇸", "Based in Miami, FL"],
    ["✉️", "j@classywatchguy.com"],
  ],
  socials: [
    ["https://linkedin.com/in/classywatchguy", fetchIcon('linkedin', 'fa')],
    ["https://instagram.com/classywatchguy", fetchIcon('instagram')],
  ],
  bio:
    "Hello! I'm Classy Watch Guy. I'm an enthusiast. I reside in Miami, and I am passionate about developing secure, scalable, and accessible real estate. I'm also passionate about wine, classic literature, art, investing, cars, and advocating for gun rights.",
  hobbies: [
    ["🛖", "developing"],
    ["📖", "reading"],
    ["🏎️", "cars"],
    ["✍", "writing"],
    ["🧑‍💻", "networking"],
  ],
  portfolio: [
    {
      title: "Audemars Piguet 2022 Double Balance Wheel Open worked Stainless Steel",
      liveLink: "#",
      sourceLink: "https://www.github.com/abdultolba/social-mern",
    },
    {
      title: "Audemars Piguet 2022 Double Balance Wheel Open worked Stainless Steel",
      liveLink: "#",
      sourceLink: "https://github.com/abdultolba/abdultolba.dev",
    },
    {
      title: "Audemars Piguet 2022 Double Balance Wheel Open worked Stainless Steel",
      liveLink: "#",
      sourceLink: "#",
    },
    {
      title: "Audemars Piguet 2022 Double Balance Wheel Open worked Stainless Steel",
      liveLink: "#",
      sourceLink: "https://github.com/abdultolba/spotify-info",
    },
  ],
};
